import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import posts from './posts'
import service from './service'

const appReducer = combineReducers({
  browser,
  location: router,
  posts,
  service,
})

export default appReducer
