import compose from 'ramda/es/compose'
import join from 'ramda/es/join'
import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import sortBy from 'ramda/es/sortBy'
import prop from 'ramda/es/prop'
import toPairs from 'ramda/es/toPairs'
import fromPairs from 'ramda/es/fromPairs'

// build query string from an object
// buildQueryString :: object -> string
export const buildQueryString = compose(
  join('&'),
  map(([k, v]) => {
    return typeof v === 'object'
      ? buildQueryString(
          compose(
            fromPairs,
            map(([k_c, v]) => [`${k}_${k_c}`, v]),
            toPairs
          )(v)
        )
      : `${k}=${encodeURIComponent(v)}`
  }),
  filter(([, v]) => !!v),
  sortBy(prop(0)),
  toPairs
)
