import { devMode } from '../../util/env'
import { buildQueryString } from '../../util/string'
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  error: null,
  searchedKsc: [],
}

const SEARCH_SERVICE_KSC = 'SEARCH_SERVICE_KSC'
const SEARCH_SERVICE_KSC_SUCCESS = 'SEARCH_SERVICE_KSC_SUCCESS'
const SEARCH_SERVICE_KSC_FAILURE = 'SEARCH_SERVICE_KSC_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SEARCH_SERVICE_KSC:
      return {
        ...state,
        isLoading: true,
      }

    case SEARCH_SERVICE_KSC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchedKsc: payload,
      }

    case SEARCH_SERVICE_KSC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }

    default:
      return state
  }
}

export const search = search => dispatch => {
  if (search) {
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang')

    const query = buildQueryString({
      search,
      lang,
    })

    dispatch({ type: SEARCH_SERVICE_KSC })

    let apiBaseUrl = WP_API_BASE

    return fetch(`${apiBaseUrl}service?${query}`)
      .then(response => {
        if (![200, 400].includes(response.status)) {
          const message = response.statusText
            ? response.statusText
            : response.status

          throw new Error(message)
        }

        response.json().then(json => {
          if ('suggestions' in json) {
            dispatch({
              type: SEARCH_SERVICE_KSC_SUCCESS,
              payload: json.suggestions,
            })
          }
        })
      })
      .catch(error => {
        if (devMode) console.error(error) //eslint-disable-line
        dispatch({
          payload: {
            error: error,
          },
          type: SEARCH_SERVICE_KSC_FAILURE,
        })
        throw error
      })
  } else {
    dispatch({
      type: SEARCH_SERVICE_KSC_SUCCESS,
      payload: [],
    })
  }
}
