import $ from 'jquery'

export default (function () {
  return function () {
    $(document).ready(function () {
      $('.js-hotspot-item').on('click', function () {
        $(this).siblings('.js-hotspot-item').removeClass('active')
        $(this).toggleClass('active')
      })

      $('.comparison-slider__wrapper').each(function () {
        const cur = $(this)
        // Adjust the slider
        const width = cur.width() + 'px'
        cur.find('.resize img').css('width', width)
        // Bind dragging events
        drags(cur.find('.handle'), cur.find('.resize'), cur)
      })
    })

    // Update sliders on resize
    $(window).resize(function () {
      $('.comparison-slider__wrapper').each(function () {
        const cur = $(this)
        const width = cur.width() + 'px'
        cur.find('.resize img').css('width', width)
      })
    })

    function drags(dragElement, resizeElement, container) {
      // Initialize the dragging event on mousedown.
      dragElement
        .on('mousedown touchstart', function (e) {
          dragElement.addClass('draggable')
          resizeElement.addClass('resizable')

          // Check if it's a mouse or touch event and pass along the correct value
          var startX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX

          // Get the initial position
          var dragWidth = dragElement.outerWidth(),
            posX = dragElement.offset().left + dragWidth - startX,
            containerOffset = container.offset().left,
            containerWidth = container.outerWidth()

          // Set limits
          const minLeft = containerOffset - 25
          const maxLeft = containerOffset + containerWidth - dragWidth + 25

          // Calculate the dragging distance on mousemove.
          dragElement
            .parents()
            .on('mousemove touchmove', function (e) {
              // Check if it's a mouse or touch event and pass along the correct value
              const moveX = e.pageX ? e.pageX : e.originalEvent.touches[0].pageX

              let leftValue = moveX + posX - dragWidth

              // Prevent going off limits
              if (leftValue < minLeft) {
                leftValue = minLeft
              } else if (leftValue > maxLeft) {
                leftValue = maxLeft
              }

              // Translate the handle's left value to masked divs width.
              const widthValue =
                ((leftValue + dragWidth / 2 - containerOffset) * 100) /
                  containerWidth +
                '%'

              // Set the new values for the slider and the handle
              // Bind mouseup events to stop dragging
              $('.comparison-slider .draggable')
                .css('left', widthValue)
                .on('mouseup touchend touchcancel', function () {
                  $(this).removeClass('draggable')
                  resizeElement.removeClass('resizable')
                })
              $('.resizable').css('width', widthValue)
            })
            .on('mouseup touchend touchcancel', function () {
              dragElement.removeClass('draggable')
              resizeElement.removeClass('resizable')
            })
          e.preventDefault()
        })
        .on('mouseup touchend touchcancel', function () {
          dragElement.removeClass('draggable')
          resizeElement.removeClass('resizable')
        })
    }
  }
})()
