/* global System */
// register component(s) with react habitat

export default {
  NotesEvents: () =>
    System.import(
      /* webpackChunkName: "NotesEvents" */
      './NotesEvents'
    ),
}
