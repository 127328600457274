import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import { LineAwesome } from 'react-line-awesome-svg'
import AngleDownSolid from 'react-line-awesome-svg/icons/AngleDownSolid'
import AngleUpSolid from 'react-line-awesome-svg/icons/AngleUpSolid'
import PlusCircleSolid from 'react-line-awesome-svg/icons/PlusCircleSolid'
import MinusCircleSolid from 'react-line-awesome-svg/icons/MinusCircleSolid'
import './styles.scss'

class AccordionWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(key) {
    const { active } = this.state

    if (active === key) {
      this.setState({ active: false })
    } else {
      this.setState({ active: key })
    }
  }

  render() {
    const { items, showBorders, usePlusMinusCaret } = this.props
    const { active } = this.state

    const activeCaret = usePlusMinusCaret ? MinusCircleSolid : AngleUpSolid
    const inactiveCaret = usePlusMinusCaret ? PlusCircleSolid : AngleDownSolid

    return (
      <div className={`dhsv_accordion ${showBorders ? 'show-borders' : ''}`}>
        {items.map((item, i) => (
          <div key={i} className={`${active === i ? 'is-open' : ''}`}>
            <div
              className="dhsv_accordion_head"
              onClick={() => this.handleClick(i)}
            >
              <button>
                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                <LineAwesome
                  icon={active === i ? activeCaret : inactiveCaret}
                />
              </button>
            </div>
            <AnimateHeight
              className="dhsv_accordion_body"
              duration={300}
              height={active === i ? 'auto' : 0}
            >
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </AnimateHeight>
          </div>
        ))}
      </div>
    )
  }
}

AccordionWrapper.propTypes = {
  items: PropTypes.array.isRequired,
  showBorders: PropTypes.bool,
  usePlusMinusCaret: PropTypes.bool,
}

export default AccordionWrapper
