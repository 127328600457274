function dhsv_vc_empty_space() {
  $('.vc_empty_space').each(function (idx, emptySpace) {
    const $emptySpace = $(emptySpace)
    const currentBreakpoint = window.bootstrapToolkit.current()

    if (currentBreakpoint) {
      const breakpoint = $emptySpace.data(currentBreakpoint)
        ? $emptySpace.data(currentBreakpoint)
        : $emptySpace.data('md')

      $emptySpace.css('height', breakpoint)
    }
  })
}

$(document).ready(dhsv_vc_empty_space)

$(window).resize(dhsv_vc_empty_space)
