/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import comparisonSliderHandler from './comparisonSliderHandler'

class ComparisonSlider extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    comparisonSliderHandler()
  }

  render() {
    const {
      image_before: imageBefore,
      image_after: imageAfter,
      text_before: text_before,
      text_after: text_after,
      text_top: text_top,
    } = this.props

    console.log(text_top) //eslint-disable-line

    return (
      <div className="row">
        <div className="col-md-12 col-lg-1"></div>
        <div className="col-md-12 col-lg-9">
          <div className="dhsv_vc_comparison_slider">
            {text_top && <p className="description">{text_top}</p>}
            <div className="comparison-slider__wrapper">
              <div className="comparison-slider__container js-comparison-container">
                <div className="comparison-slider__inner">
                  <img className="comparison-slider__image" src={imageAfter} />
                </div>
                <div className="resize">
                  <img className="comparison-slider__image" src={imageBefore} />
                </div>
                <span className="handle" />
                {text_after && <h3 className="text-after">{text_after}</h3>}
                {text_before && <h3 className="text-before">{text_before}</h3>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-2"></div>
      </div>
    )
  }
}

ComparisonSlider.propTypes = {
  image_before: PropTypes.string,
  text_before: PropTypes.string,
  image_after: PropTypes.string,
  text_after: PropTypes.string,
  text_top: PropTypes.string,
}

export default ComparisonSlider
