import $ from 'jquery'

$.fn.isInViewport = function () {
  let elementTop = $(this).offset().top,
    elementBottom = elementTop + $(this).outerHeight() / 2,
    viewportTop = $(window).scrollTop(),
    viewportHalf = viewportTop + $(window).height() / 2

  return elementBottom > viewportTop && elementTop < viewportHalf
}

$.fn.isInViewportSecond = function () {
  let elementTop = $(this).offset().top + 200,
    elementBottom = elementTop + $(this).outerHeight() - 350,
    viewportTop = $(window).scrollTop(),
    viewportHalf = viewportTop + $(window).height()
  return elementBottom > viewportTop && elementTop < viewportHalf
}

$(window).on('load resize scroll', function () {
  if ($('.timeline').length > 0) {
    $('.timeline .timeline-item').each(function () {
      if ($(this).isInViewport()) {
        $(this).removeClass('hide-elem')
      }
    })
    $('.timeline .timeline__title').each(function () {
      if ($(this).isInViewport()) {
        $(this).removeClass('hide-elem')
      }
    })
    $('.timeline').each(function () {
      const $line = $(this).find('.timeline-line')
      if ($(this).isInViewportSecond()) {
        $line.removeClass('hide-elem')
        let elementTop = $(this).offset().top,
          viewportTop = $(window).scrollTop() + 200,
          lineHight = window.innerHeight * 0.3,
          increment = viewportTop - elementTop

        increment = Math.round(increment)
        $line.height(increment + lineHight)
      }
    })
  }
})
